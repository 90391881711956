<template>
  <div>
    <div class="page-header page-header-small">
      <parallax
        class="page-header-image"
        style="background-image: url('img/truck1.jpg')"
      >
      </parallax>
      <div class="content-center">
        <div class="container">
          <h1 class="title h1-seo">Transport Global Security S.A.C</h1>
          <div class="text-center">
            <a href="#" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-facebook-square"></i>
            </a>
            <a href="#" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-twitter"></i>
            </a>
            <a href="#" class="btn btn-primary btn-icon btn-round">
              <i class="fab fa-google-plus"></i>
            </a>
          </div>
        </div>
      </div>
    </div>
    <div class="section section-about-us">
      <div class="container">
        <div class="row">
          <div class="col-md-8 ml-auto mr-auto text-center">
            <h2 class="title">¿Quienes somos?</h2>
            <h5 class="description">
              Somos una empresa peruana especializada en el transporte de carga y operaciones logísticas a nivel nacional.
               Contamos con personal altamente capacitado, quienes están a su disposición para satisfacer sus necesidades de transporte terrestre y carga pesada a nivel nacional,
                garantizando la satisfacción en los servicios brindados, a través del trabajo en equipo y capacitación constante de nuestro personal.
            </h5>
          </div>
        </div>
        <div class="separator separator-primary"></div>
        <div class="section-story-overview">
          <div class="row">
            <div class="col-md-6">
              <div
                class="image-container image-left"
                style="background-image: url('img/truck2.jpg')"
              >
                <!-- First image on the left side -->
                <p class="blockquote blockquote-primary">
                  <strong>
                     Misión:
                    </strong>
                    Brindar un excelente servicio de transporte
                    a nuestros clientes actuales de forma más eficiente y segura.
                  <br />
                </p>
              </div>
              <!-- Second image on the left side of the article -->
              <!-- <div
                class="image-container"
                style="background-image: url('img/truck3.jpg')"
              ></div> -->
            </div>
            <div class="col-md-5">
              <!-- First image on the right side, above the article -->
              <div
                class="image-container image-right"
                style="background-image: url('img/truck4.jpg')"
              ></div>
   
              <p>
                Nuestras unidades cumplen con un mantenimiento mecánico adecuado para optimizar el correcto funcionamiento durante nuestras operaciones asignadas. Cada unidad cuenta con GPS. Los datos para acceder a nuestra plataforma MONITOREO GPS serán compartidas a solicitud de quien requiera nuestro Servicio de Carga para dar seguimiento en tiempo real de la misma.
Adicional a ello contamos con un soporte técnico y Monitoreo de su carga en tiempo real por nuestro MONITOR GPS, quien tiene a su cargo dar seguimiento y estar en comunicación constante con nuestros conductores, teniendo procedimiento de reacción inmediata ante una eventualidad.
Documentación correcta y actualizada en los sistemas para accesos a Los Puertos DP WORLD CALLAO y APM TERMINALS CALLAO, también para la mayoría de almacenes asociados a las terminales portuarias.
Contamos con 1 año de experiencia en el servicio de transporte de carga por carretera a nivel nacional y que hoy en día se ha fortalecido gracias a nuestros clientes.
              </p>

            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="section section-team text-center">
      <div class="container">
        <h2 class="title">Here is our team</h2>
        <div class="team">
          <div class="row">
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/avatar.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Romina Hadid</h4>
                <p class="category text-primary">Model</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-instagram"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-facebook-square"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/ryan.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Ryan Tompson</h4>
                <p class="category text-primary">Designer</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-linkedin"></i
                ></a>
              </div>
            </div>
            <div class="col-md-4">
              <div class="team-player">
                <img
                  src="img/eva.jpg"
                  alt="Thumbnail Image"
                  class="rounded-circle img-fluid img-raised"
                />
                <h4 class="title">Eva Jenner</h4>
                <p class="category text-primary">Fashion</p>
                <p class="description">
                  You can write here details about one of your team members. You
                  can give more details about what they do. Feel free to add
                  some <a href="#">links</a> for people to be able to follow
                  them outside the site.
                </p>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-google-plus"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-youtube"></i
                ></a>
                <a href="#pablo" class="btn btn-primary btn-icon btn-round"
                  ><i class="fab fa-twitter"></i
                ></a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
    <div class="section section-contact-us text-center">
      <div class="container">
        <h2 class="title">¿Deseas contactarnos?</h2>
        <p class="description">Sus proyectos son muy importantes para nosotros.</p>
        <div class="row">
          <div class="col-lg-6 text-center ml-auto mr-auto col-md-8">
            <fg-input
              class="input-lg"
              placeholder="Su Nombre..."
              v-model="form.firstName"
              addon-left-icon="now-ui-icons users_circle-08"
            >
            </fg-input>
            <fg-input
              class="input-lg"
              placeholder="Su Email.."
              v-model="form.email"
              addon-left-icon="now-ui-icons ui-1_email-85"
            >
            </fg-input>
            <div class="textarea-container">
              <textarea
                class="form-control"
                name="name"
                rows="4"
                cols="80"
                v-model="form.message"
                placeholder="Escriba un mensaje..."
              ></textarea>
            </div>
            <div class="send-button">
              <n-button type="primary" round block size="lg"
                >Enviar</n-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { Button, FormGroupInput } from '@/components';
export default {
  name: 'landing',
  bodyClass: 'landing-page',
  components: {
    [Button.name]: Button,
    [FormGroupInput.name]: FormGroupInput
  },
  data() {
    return {
      form: {
        firstName: '',
        email: '',
        message: ''
      }
    };
  }
};
</script>
<style></style>
