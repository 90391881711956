<template>
  <footer
    class="footer"
    :class="{ [`footer-${type}`]: type }"
    :data-background-color="backgroundColor"
  >
    <div class="container">
      <div class="pt-3 pb-3">
        <div class="row d-flex">
          <div class="col-md-4 col-sm-12 pb-2">
            <strong>
            Atención al cliente 
            </strong>
            <i class="now-ui-icons tech_mobile"></i>
            : 998903546
          </div>
          
          <div class="col-md-4 pb-2">
            <strong>
              Horario de atención 
            </strong>
            <i class="now-ui-icons ui-2_time-alarm"></i>
            : Lunes a viernes de 8:00am - 5:00pm
            Sabados de 9:00am - 1:00pm
          </div>
          <div class="col-md-4 pb-2">
            <strong>
              Dirección 
            </strong>
            <i class="now-ui-icons location_pin"></i>
            <strong>: </strong> MZ. E LT. 04 A.H SANTA FE - VENTANILLA
          </div>
        </div>
      </div>
    </div>
    <div class="container">
      <nav>
        <ul>
          <li>
            <a href="#">
              <strong>
              Contacto :
              </strong>
            </a>
          </li>
          <li>
            <a href="mailto:info@transportglobalsecurity.com" style="text-transform:lowercase;font-size:1.1rem;">
              info@transportglobalsecurity.com
            </a>
          </li>
        </ul>
      </nav>
      <div class="copyright pt-1">
        &copy; {{ year }}, Designed by
        <a href="https:///www.invisionapp.com" target="_blank" rel="noopener"
          >Invision</a
        >. Coded by
        <a href="https://binarcode.com" target="_blank" rel="noopener"
          >BinarCode</a
        >
        and
        <a href="https://www.creative-tim.com" target="_blank" rel="noopener"
          >Creative Tim</a
        >.
      </div>
    </div>
  </footer>
</template>
<script>
export default {
  props: {
    backgroundColor: String,
    type: String
  },
  data() {
    return {
      year: new Date().getFullYear()
    };
  }
};
</script>
<style></style>
